import PropTypes from 'prop-types'
import React from 'react'
import Reveal from 'react-reveal/Reveal'

export default class SectionHead extends React.Component {
  render() {
    const { title, link, url } = this.props
    return (
      <div className='section__header container'>
        <Reveal effect='fadeInUp'>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </Reveal>
      </div>
    )
  }
}

SectionHead.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  url: PropTypes.string,
}
