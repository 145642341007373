import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import SectionHead from '../components/SectionHead'

export const FrontTemplate = () => {
  return (
    <section className='hero is-medium'>
      <SectionHead title='Site under maintenance.' />
      <div className='columns is-multiline is-vcentered'>
        <Reveal effect='fadeInUpSlow'>
          <div className='hero__content column is-10-mobile is-offset-1-mobile is-12-tablet is-4-desktop is-5-widescreen'>
            <div className='columns is-multiline'>
              <div className='column is-offset-1-tablet is-5-tablet is-11-desktop is-9-widescreen is-offset-1-desktop'>
                <h2 className='is-size-2 has-font-weight-light'>Current exhibition: "Image Practices"</h2>
                <p>
                  <strong>Exhibition dates:</strong> August 17 – September 15, 2024
                  <br />
                  <strong>Opening hours:</strong> 10 AM – 6 PM,
                  <br />
                  Tuesday to Sunday Closed on Monday and public holidays
                  <br />
                  <strong>Free admission</strong>
                </p>
                <p>Organised and presented by MNB Arts & Culture</p>

                <p>110 Andrássy Avenue Budapest, 1062 Hungary</p>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </section>
  )
}

const Page = () => {
  return (
    <div className='front-page'>
      <Helmet title={`Q Contemporary`} />
      <FrontTemplate />
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page
